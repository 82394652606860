<template>
  <div class="DataAnalysis">
    <div class="box">
      <div class="left leftFlex">
        <router-view></router-view>
      </div>
      <div class="right recommendRightList">
        <recommend />
      </div>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
import commonFooter from "components/footer/commonFooter.vue";
import recommend from "components/recommend/recommend.vue";
export default {
  components: {
    recommend,
    commonFooter,
  },
};
</script>

<style lang="scss" scoped>
.DataAnalysis {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #f9f9f9;
  .box {
    display: flex;
    width: 1338px;
    box-sizing: border-box;
    margin: 0 auto;
    .left {
      position: relative;
      width: 936px;
      margin-right: 20px;
      display: flex;
      background-color: #fff;
    }
    .right {
      background-color: #fff;
      flex: 1;
    }
  }

  .commonFooter_box {
    margin-top: 20px;
    width: 100%;
  }
}
</style>
