import request from "./request";

// 获取小课堂列表
export function getListById(params) {
  return request({
    url: "/helpManualComment/getListById",
    method: "GET",
    params,
  });
}

// 获取小课堂分类列表
export function getClassList() {
  return request({
    url: "/helpManualComment/getClassList",
    method: "GET",
  });
}

// 获取小课堂详情
export function getClassContentById(params) {
  return request({
    url: "/helpManualComment/getClassContentById",
    method: "GET",
    params,
  });
}

// 获取视频评论列表
export function getContentById(data) {
  return request({
    url: "/helpManualComment/list",
    method: "post",
    data,
  });
}

// 评论点赞/取消
export function praise(data) {
  return request({
    url: "/helpManualComment/praise",
    method: "post",
    data,
  });
}

// 评论点赞/取消
export function helpManualCommentAdd(data) {
  return request({
    url: "/helpManualComment/add",
    method: "post",
    data,
  });
}
